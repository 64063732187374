import React, { useState, useEffect } from "react";
import { Page, pdfjs, Document } from "react-pdf";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import MUICookieConsent from "material-ui-cookie-consent";
import { SizeMe } from "react-sizeme";

import menukaartPDF from "../Menukaart-2020_compressed.pdf";
import "../App.css";

const url = process.env.REACT_APP_GOOGLE_SHEET;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export default function RegisterForm() {
  const classes = useStyles();
  const [firstName, setFirstName] = useState({ value: "", error: false });
  const [lastName, setLastName] = useState({ value: "", error: false });
  const [phoneNumber, setPhoneNumber] = useState({ value: "", error: false });
  const [emailAddress, setEmailAddress] = useState({ value: "", error: false });
  const [showMenu, setShowMenu] = useState(false);
  const [numPages, setNumPages] = useState();
  const [loading, setLoading] = useState({ isLoading: false });
  const [customers, setCustomers] = useState({
    customersArray: [],
  });

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const fetchData = async (data, dt) => {
    fetch(url, {
      method: "post",
      body: data,
    })
      .then((data) => {
        // Set cookie
        // If request successfull set cookie
        dt.setHours(dt.getHours() + 1);
        document.cookie = `validuntil=${dt.toUTCString()};expires=${dt.toUTCString()};path=/`;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const processValues = () => {
    var dt = new Date();

    // Post data to excel sheet
    for (let i = 0; i < customers.customersArray.length; i++) {
      const customer = customers.customersArray[i];
      const data = new URLSearchParams();

      data.append("timestamp", dt.toLocaleString());
      data.append("firstName", customer.first_name.value);
      data.append("lastName", customer.last_name.value);
      data.append("email", customer.email_address.value);
      data.append("phone", customer.phone_number.value);

      fetchData(data, dt);
    }

    // Show menu
    setShowMenu(true);
  };

  useEffect(() => {
    // Get cookie
    var cookieExpire = new Date(getCookie("validuntil"));
    var currentDate = new Date();

    if (cookieExpire >= currentDate) {
      setShowMenu(true);
    }
  }, []);

  useEffect(() => {
    var process = false;

    // Check if we can start sending data
    for (let i = 0; i < customers.customersArray.length; i++) {
      const element = customers.customersArray[i];

      if (element.post_data) {
        process = element.post_data;
        break;
      }
    }

    // Post data to form
    if (process) {
      processValues();
      setLoading({ isLoading: false });
    }
  }, [customers]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  function ValidateForm() {
    let formIsValid = true;
    if (!phoneNumber.value.match(/^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/)) {
      setPhoneNumber({ value: phoneNumber.value, error: true });
      formIsValid = false;
    }

    if (!emailAddress.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) && emailAddress.value !== "") {
      setEmailAddress({ value: emailAddress.value, error: true });
      formIsValid = false;
    }

    if (firstName.value === "") {
      setFirstName({ value: firstName.value, error: true });
      formIsValid = false;
    }

    if (lastName.value === "") {
      setLastName({ value: lastName.value, error: true });
      formIsValid = false;
    }

    return formIsValid;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="App">
      {showMenu ? (
        <div>
          <SizeMe
            monitorHeight
            refreshRate={128}
            refreshMode={"debounce"}
            render={({ size }) => (
              <div>
                <Document
                  file={menukaartPDF}
                  options={{ workerSrc: "/pdf.worker.js" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={size.width}
                    />
                  ))}
                </Document>
              </div>
            )}
          />
        </div>
      ) : (
          <Container component="main" maxWidth="xs">
            <MUICookieConsent
              cookieName="AcceptCookie"
              message="Wij plaatsen noodzakelijke cookies voor het functioneren van deze website. De door uw achtergelaten gegevens worden verwerkt conform de richtlijnen van de overheid."
            />
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <RestaurantIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Bezoekersregistratie - Cafe Op de Hoek!{" "}
              </Typography>
              <Typography component="h6" variant="h6">
                i.v.m. Covid 19
            </Typography>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="Voornaam"
                      autoFocus
                      value={firstName.value}
                      error={firstName.error}
                      helperText={
                        firstName.error ? "Voornaam is missend" : ""
                      }
                      onChange={(e) => {
                        setFirstName({ value: e.target.value, error: false });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Achternaam"
                      name="lastName"
                      autoComplete="lname"
                      error={lastName.error}
                      helperText={
                        lastName.error ? "Achternaam is missend" : ""
                      }
                      value={lastName.value}
                      onChange={(e) => {
                        setLastName({ value: e.target.value, error: false });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="phone"
                      label="Telefoonnummer"
                      name="phone"
                      autoComplete="phone"
                      error={phoneNumber.error}
                      helperText={
                        phoneNumber.error ? "Telefoon nummer incorrect" : ""
                      }
                      value={phoneNumber.value}
                      onChange={(e) => {
                        setPhoneNumber({ value: e.target.value, error: false });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      error={emailAddress.error}
                      helperText={emailAddress.error ? "Mailadres incorrect" : ""}
                      id="email"
                      label="Emailadres"
                      name="email"
                      autoComplete="email"
                      value={emailAddress.value}
                      onChange={(e) => {
                        setEmailAddress({ value: e.target.value, error: false });
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type=""
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => {
                    e.preventDefault();

                    if (ValidateForm()) {
                      // Add to customersarray
                      setCustomers((customers) => {
                        return {
                          customersArray: customers.customersArray.concat({
                            first_name: firstName,
                            last_name: lastName,
                            phone_number: phoneNumber,
                            email_address: emailAddress,
                            post_data: false,
                          }),
                        };
                      });

                      // Clear all fields
                      setFirstName({ value: "", error: false });
                      setLastName({ value: "", error: false });
                      setEmailAddress({ value: "", error: false });
                      setPhoneNumber({ value: "", error: false });
                    }
                  }}
                >
                  Extra Klant Toevoegen
              </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => {
                    e.preventDefault();

                    // When a customer already filled in some names
                    if (firstName.value === "" && lastName.value === "" && phoneNumber.value === "" && customers.customersArray.length > 0) {
                      // change set post_date to true to trigger useeffect
                      setLoading({ isLoading: true });
                      processValues()
                      setLoading({ isLoading: false });
                      return;
                    }

                    if (ValidateForm()) {
                      setLoading({ isLoading: true });
                      // append to array
                      setCustomers((customers) => {
                        return {
                          customersArray: customers.customersArray.concat({
                            first_name: firstName,
                            last_name: lastName,
                            phone_number: phoneNumber,
                            email_address: emailAddress,
                            post_data: true,
                          }),
                        };
                      });
                    }
                  }}
                >
                  {loading.isLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                      "Klaar! Ga naar Menu!"
                    )}
                </Button>
              </form>
              <Box m={1}>
                <Typography variant="body2" color="textSecondary" align="center">
                  <Link color="inherit" href="https://meldaan.app/">
                    Personenregistratie nodig? Meldaan.App!
                </Link>
                </Typography>
              </Box>

              {customers.customersArray.map((cust, index) => (
                <div key={`div_snack_${index + 1}`} className="snackbar">
                  {!cust.post_data ? (
                    <SnackbarContent
                      key={`page_${index + 1}`}
                      message={`Voornaam: ${cust.first_name.value}, Achternaam: ${cust.last_name.value}, Telefoonnummer: ${cust.phone_number.value}, Email: ${cust.email_address.value}`}
                    ></SnackbarContent>
                  ) : null}
                </div>
              ))}
            </div>
          </Container>
        )}
    </div>
  );
}
